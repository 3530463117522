<template>
  <div v-if="!!states && !!companies && !!allLawyers && !!allEstablishments">
    <vs-sidebar
      v-if="!!panelSidebar"
      click-not-close
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="penal-retail-sidebar items-no-padding"
      spacer
      v-model="panelSidebar"
    >
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ $t("customers.labels.periodTitle") }}</h4>
        <div>
          <feather-icon
            icon="XIcon"
            @click.stop="panelSidebar = null"
            class="cursor-pointer"
          ></feather-icon>
        </div>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <div class="p-0">
        <VuePerfectScrollbar
          class="scroll-area--data-list-add-new"
          :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.6 }"
          :key="$vs.rtl"
        >
          <div class="vx-col w-full mb-2 sm:w-1/1 py-3 px-6">
            <label class="vs-input--label">{{
              $t("customers.labels.period")
            }}</label>
            <br />
            <datetime
              :value="period"
              type="date"
              input-class="flatpickr-input"
              format="LLLL yyyy"
              :flow="['month', 'year']"
              :phrases="{
                ok: $t('buttons.ok'),
                cancel: $t('buttons.cancel'),
              }"
              name="period"
              value-zone="UTC"
              zone="UTC"
              @input="(e) => changePeriod(e)"
            />
          </div>
        </VuePerfectScrollbar>
      </div>
      <div class="footer-sidebar" slot="footer">
        <div class="p-3" v-if="panelSidebar.type === 'actions'">
          <vs-button
            color="primary"
            class="w-full"
            @click="createActionsReport"
            icon="gavel"
          >
            {{ $t("customers.buttons.createActionsXLS") }}
          </vs-button>
        </div>
        <div class="p-3" v-else-if="panelSidebar.type === 'report'">
          <vs-button
            color="danger"
            class="w-full mb-2"
            @click="createPDFReport"
            icon-pack="feather"
            icon="icon-book"
          >
            {{ $t("customers.buttons.createReportPDF") }}
          </vs-button>
          <vs-button
            color="success"
            class="w-full"
            @click="createXLSReport"
            icon-pack="feather"
            icon="icon-book"
          >
            {{ $t("customers.buttons.createReportXLS") }}
          </vs-button>
        </div>
      </div>
    </vs-sidebar>

    <FormPage
      :collectionName="collectionName"
      :tabs="tabs"
      :prevalidate="prevalidate"
      :id="$route.params.id"
    >
      <template v-slot:customButtons="{ entity, lawyer }">
        <vs-button
          color="warning"
          class="mr-3 mb-2 float-right"
          @click="panelSidebar = { type: 'report' }"
          icon-pack="feather"
          icon="icon-book"
        >
          {{ $t("customers.buttons.report") }}
        </vs-button>
        <vs-button
          color="success"
          class="mr-3 mb-2 float-right"
          @click="panelSidebar = { type: 'actions' }"
          icon="gavel"
        >
          {{ $t("customers.buttons.actions") }}
        </vs-button>
        <vs-button
          color="danger"
          class="mr-3 mb-2 float-right"
          @click="createGlobalReport"
          icon-pack="feather"
          icon="icon-book"
        >
          {{ $t("customers.buttons.globalReport") }}
        </vs-button>
      </template>
    </FormPage>
  </div>
  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import { db } from "@/firebase/firebaseConfig";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import FormPage from "@/components/penal-retail-helpers/FormPage";
import customersFormConfig from "@/helpers/customersFormConfig";
import companiesFormConfig from "@/helpers/companiesFormConfig";

import "vue-datetime/dist/vue-datetime.css";
import { Datetime } from "vue-datetime";

import { Settings } from "luxon";

let collectionName = "customers";

export default {
  components: {
    FormPage,
    Datetime,
    VuePerfectScrollbar,
  },
  computed: {
    ...mapGetters(["allEstablishments", "allLawyers"]),
    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
  },
  created() {
    Settings.defaultLocale = this.$i18n.locale;
    this.$bind(
      "data",
      db.collection("establishments").doc(this.$route.params.id),
      { wait: true }
    );

    this.$bind(
      "companies",
      db
        .collection(`customers/${this.$route.params.id}/companies`)
        .where("deleted", "==", false),
      { wait: true }
    );

    this.$bind("states", db.collection("states").orderBy("alias"), {
      wait: true,
    });
  },
  data() {
    let rows = customersFormConfig(this);

    return {
      period: this.$moment(firebase.firestore.Timestamp.now().toDate())
        .add(-1, "month")
        .startOf("month")
        .add(1, "day") //Ponemos en el 2do día por el problema de diferencia horaria
        .toISOString(),
      panelSidebar: null,
      states: null,
      companies: null,
      data: null,
      collectionName,
      tabs: [
        { name: `${collectionName}.tabs.general`, rows },
        {
          name: `${collectionName}.tabs.companies`,
          attributes: this.defineCompaniesTab(),
        },
        {
          name: `${collectionName}.tabs.establishments`,
          attributes: this.defineEstablishmentsTab(),
        },
      ],
    };
  },
  methods: {
    defineCompaniesTab() {
      let customerId = this.$route.params.id;

      let onRemove = async (id) => {
        await db
          .collection(`customers/${customerId}/companies`)
          .doc(id)
          .update({ deleted: true });
      };

      let prevalidate = async (data, collection, errors) => {
        if (data.alias) {
          let snapshot = await collection
            .where("alias", "==", data.alias)
            .where("deleted", "==", false)
            .get();

          if (snapshot.docs && snapshot.docs.length > 0) {
            errors.add({
              field: "alias",
              msg: this.$t(`companies.validations.alias.unique`),
            });
            return true;
          }
        }

        return false;
      };

      let columns = [];
      ["cif", "alias"].forEach((a) => {
        columns.push({
          headerName: this.$t(`companies.columns.${a}`),
          filter: false,
          width: a === "cif" ? 30 : 70,
          field: a,
        });
      });

      columns.push({
        headerName: "",
        filter: false,
        width: 50,
        field: "id",
        cellRendererFramework: "CellRendererRemove",
        onRemove,
      });

      let filters = [];
      filters.push({
        size: "1/1",
        mobileSize: "1/1",
        sidebar: true,
        i18n: `companies.buttons.new`,
        extraClasses: "text-right",
        sidebarConfig: {
          title: `companies.buttons.new`,
          collectionName: `companies`,
          realCollectionName: `customers/${customerId}/companies`,
          attributes: companiesFormConfig,
          prevalidate,
        },
      });

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.companies;
          },
          filters,
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
          },
        },
      });

      return config;
    },
    defineEstablishmentsTab() {
      let customerId = this.$route.params.id;
      let columns = [
        {
          headerName: this.$t(`establishments.columns.code`),
          filter: false,
          width: 75,
          field: "code",
        },
        {
          headerName: this.$t(`establishments.columns.alias`),
          field: "alias",
          filter: false,
          width: 125,
        },
        {
          headerName: this.$t(`establishments.columns.city`),
          field: "city",
          filter: false,
          width: 150,
        },
        {
          headerName: this.$t(`establishments.columns.zip`),
          field: "zip",
          filter: false,
          width: 50,
        },
        {
          headerName: this.$t(`establishments.columns.state`),
          field: "state",
          filter: false,
          width: 150,
          valueGetter: (params) => {
            return params.data.state ? params.data.state.alias : null;
          },
        },
      ];

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.allEstablishments.filter(
              (x) => x.id !== "empresa" && x.customer.id === customerId
            );
          },
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
          },
        },
      });

      return config;
    },
    async prevalidate(model, collection, errors) {
      const otherCodes = (
        await collection.where("code", "==", model.code).get()
      ).docs
        .filter((x) => x.exists)
        .filter((x) => x.id !== this.$route.params.id);
      if (otherCodes.length > 0) {
        errors.add({
          field: "code",
          msg: this.$t(`${collectionName}.validations.code.unique`),
        });
      }
    },
    changePeriod(newPeriod) {
      if (newPeriod) {
        this.period = newPeriod;
      }
    },

    async createActionsReport() {
      let customerId = this.$route.params.id;
      let period = this.$moment(this.period).format("YYYY-MM");
      window.open(
        `${process.env.VUE_APP_FUNCTIONS_URL}/createActionsXLSForCustomer?customerId=${customerId}&period=${period}`
      );
      this.panelSidebar = null;
    },
    async createPDFReport() {
      let customerId = this.$route.params.id;
      let period = this.$moment(this.period).format("YYYY-MM");
      window.open(
        `${process.env.VUE_APP_FUNCTIONS_URL}/createPDFForCustomer?customerId=${customerId}&period=${period}`
      );
      this.panelSidebar = null;
    },
    async createXLSReport() {
      let customerId = this.$route.params.id;
      let period = this.$moment(this.period).format("YYYY-MM");
      window.open(
        `${process.env.VUE_APP_FUNCTIONS_URL}/createXLSForCustomer?customerId=${customerId}&period=${period}`
      );
      this.panelSidebar = null;
    },

    createGlobalReport() {
      let customerId = this.$route.params.id;
      window.open(
        `${process.env.VUE_APP_FUNCTIONS_URL}/createGlobalReportForCustomer?customerId=${customerId}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.penal-retail-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 45px);
}
</style>
<style lang="scss">
.penal-retail-sidebar {
  [tagcolor="danger"] {
    color: rgba(var(--vs-danger), 0.5);
  }

  .activeChild button[tagcolor="danger"],
  button:not(:disabled)[tagcolor="danger"]:hover {
    color: rgba(var(--vs-danger), 1) !important;
    background-color: rgba(var(--vs-danger), 0.1) !important;
  }

  .activeChild button:not([tagcolor="danger"]) {
    background-color: rgba(var(--vs-primary), 0.1) !important;
  }

  .line-vs-tabs {
    display: none;
  }

  .vs-tabs--content {
    padding: 0;
  }
}

.flatpickr-input {
  width: 100%;
}
</style>
