import casesTypesOptions from "@/data/generic/casesTypes";

import countries from "@/data/generic/countries";

import { countryStates } from "@/helpers/utils";

let fields = (self) => {
  let fields1 = [];
  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "code",
    validation: "required",
  });
  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "alias",
    validation: "required",
  });
  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "bussinessName",
    validation: "required",
  });

  let fields2 = [];
  fields2.push({
    size: "1/2",
    type: "input",
    attribute: "address",
    validation: "required",
  });
  fields2.push({
    size: "1/2",
    type: "input",
    attribute: "address2",
    validation: "",
  });
  fields2.push({
    size: "1/6",
    type: "input",
    attribute: "zip",
    validation: "required|digits:5",
  });
  fields2.push({
    size: "1/3",
    type: "input",
    attribute: "city",
    validation: "required",
  });
  fields2.push({
    size: "1/4",
    type: "enum",
    attribute: "country",
    validation: "required",
    extra: {
      enumType: "countries",
      options: countries,
    },
  });
  fields2.push({
    size: "1/4",
    type: "select",
    attribute: "state",
    validation: "required",
    disabledFunction: (data) => !data.country,
    extra: {
      optionsFunction: (m) => (m.country ? countryStates(m.country.id) : []),
    },
  });

  let fields3 = [];
  fields3.push({
    size: "1/6",
    type: "input",
    attribute: "phone",
    validation: "digits:9",
  });
  fields3.push({
    size: "1/6",
    type: "input",
    attribute: "fax",
    validation: "digits:9",
  });
  fields3.push({
    size: "1/4",
    type: "multiple-select",
    attribute: "casesTypes",
    validation: "required",
    extra: {
      optionsFunction: () => casesTypesOptions,
    },
  });
  fields3.push({
    size: "1/4",
    type: "select",
    attribute: "lawyer",
    validation: "required",
    extra: {
      optionsFunction: () => self.allLawyers,
    },
  });
  fields3.push({
    size: "1/6",
    type: "boolean",
    attribute: "force2FA",
  });

  return [fields1, fields2, fields3].map((attributes) => ({ attributes }));
};

export default fields;
